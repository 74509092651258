<template>
    <div class="wrapper">
        <v-head></v-head>
        <v-sidebar @width="getVal"></v-sidebar>
        <div class="content-box" :style="{left: width+ 'px' }">
            <v-tags></v-tags>
            <div class="content">
                <transition name="move" mode="out-in">
                    <keep-alive :include="tagsList">
                        <router-view></router-view>

                    </keep-alive>
                </transition>

                <!-- 挂载子应用 开始 -->
                <section id="Appmicro" />
                <!-- 挂载子应用 结束 -->

                <el-backtop target=".content"></el-backtop>
            </div>

        </div>
    </div>
</template>

<script>
import vHead from './Header.vue';
import vSidebar from './Sidebar.vue';
import vTags from './Tags.vue';
import bus from '../common/bus';
export default {
    data() {
        return {
            tagsList: [],
            collapse: false,
            width: 120
        };
    },
    components: {
        vHead,
        vSidebar,
        vTags
    },
    created() {
        // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
        bus.$on('tags', msg => {
            let arr = [];
            for (let i = 0, len = msg.length; i < len; i++) {
                msg[i].name && arr.push(msg[i].name);
            }
            this.tagsList = arr;
        });

    },
    methods: {
        getVal(val) {
            this.width = val
        }
    }
};
</script>

<style scoped>
</style>