<template>
    <div class="header">
        <!-- 折叠按钮 -->
        <div class="collapse-btn">
            <img :src="storeInfo.logo" alt />
            <!-- <i v-if="!collapse" class="el-icon-s-fold"></i> -->
            <!-- <i v-else class="el-icon-s-unfold"></i> -->
        </div>
        <div class="logo">{{storeInfo.name}}</div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 全屏显示 -->
                <div class="btn-fullscreen" @click="handleFullScreen">
                    <el-tooltip effect="dark" :content="fullscreen?`取消全屏`:`全屏`" placement="bottom">
                        <i class="el-icon-rank"></i>
                    </el-tooltip>
                </div>
                <!-- 消息中心 -->
                <div class="btn-bell">
                    <!-- <el-tooltip
                        effect="dark"
                        :content="message?`有${message}条未读消息`:`消息中心`"
                        placement="bottom"
                    >
                        <router-link to="/tabs">
                            <i class="el-icon-bell"></i>
                        </router-link>
                    </el-tooltip>
                    <span class="btn-bell-badge" v-if="message"></span>-->
                </div>
                <!-- 用户头像 -->
                <div class="user-avator">
                    <img :src="param.avatar" />
                </div>
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <a href="https://github.com/lin-xin/vue-manage-system" target="_blank">
                            <el-dropdown-item>项目仓库</el-dropdown-item>
                        </a>-->
                        <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                        <el-dropdown-item divided command="changePassword">修改密码</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <el-dialog title="修改密码" :visible.sync="confirmVisible" width="300px" center>
            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <el-form-item prop="password">
                    <el-input type="password" placeholder="password" v-model="param.password" @keyup.enter.native="submitForm()" class="input">
                        <el-button slot="prepend" icon="el-icon-lx-lock"></el-button>
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitForm()">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import bus from '../common/bus';
import { request } from '@/common/request';
import { mapState, mapActions } from "vuex";
export default {
    data() {
        return {
            collapse: false,
            fullscreen: false,
            name: 'linxin',
            message: 2,
            pageInfo: {},
            defaultAvatar:"../assets/img/img.jpg",
            confirmVisible: false,
            param: {
                password: ''
            },
            rules: {
                password: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
                }],
            }
        };
    },
    computed: {
        username() {
            let user = localStorage.getItem('user')
            let ms_username = localStorage.getItem('ms_username')
            if (!user || user === 'undefined') {
                return ms_username;
            }
            let users = JSON.parse(user)
            this.param = users ? users : {}
            this.param.password = ''
            if (this.param.avatar == null){
                this.param.avatar=this.defaultAvatar;
            }
            return users.username ? users.username : this.name;
        },
        ...mapState({
            storeInfo: state => state.storeInfo
        })
    },
    created() {
    },
    methods: {
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if (command == 'loginout') {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('siderRouter');
                this.$store.commit("adminRouter/siderRouter", [])
                this.$router.push('/login');
            }
            if (command == 'changePassword') {
                this.confirmVisible = true
            }
        },
        // 修改密码
        submitForm() {
            let that = this;
            this.$refs.login.validate(valid => {
                if (valid) {
                    request.post("/user/edit", this.param).then((ret) => {
                        if (ret.code == 1) {
                            that.$message.success('修改成功');
                            that.$router.push('/login');
                        } else {
                            that.$message.error(ret.msg);
                        }

                    })
                } else {
                    this.$message.error('请输入密码');
                    return false;
                }
            });
        },
        // 侧边栏折叠
        collapseChage() {
            this.collapse = !this.collapse;
            bus.$emit('collapse', this.collapse);
        },
        // 全屏事件
        handleFullScreen() {
            let element = document.documentElement;
            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
            }
            this.fullscreen = !this.fullscreen;
        }
    },
    mounted() {
        if (document.body.clientWidth < 1500) {
            this.collapseChage();
        }
    }
};
</script>
<style scoped>
.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    font-size: 22px;
    color: #fff;
}

.collapse-btn {
    height: 50px;
    float: left;
    cursor: pointer;
    padding: 10px 20px 0;
}
.collapse-btn img {
    width: 30px;
    height: 30px;
}

.header .logo {
    float: left;
    width: 250px;
    font-size: 15px;
    line-height: 50px;
}

.header-right {
    float: right;
    padding-right: 50px;
}

.header-user-con {
    display: flex;
    height: 50px;
    align-items: center;
}

.btn-fullscreen {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}

.btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}

.btn-bell .el-icon-bell {
    color: #fff;
}

.user-name {
    margin-left: 10px;
}

.user-avator {
    margin-left: 20px;
}

.user-avator img {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.el-dropdown-link {
    color: #fff;
    cursor: pointer;
}

.el-dropdown-menu__item {
    text-align: center;
}

.login-btn {
    display: flex;
    justify-content: center;
}
</style>
