<template>

    <div id="sild">
        <div class="sidebar">
            <el-menu class="sidebar-el-menu" text-color="#999" unique-opened @open="open" @close="close" router>
                <template v-for="item in items" v-if="item.is_show==2">
                    <template>
                        <el-submenu :index="item.index" :key="item.id">
                            <template slot="title">
                                <i :class="item.icon"></i>
                                <span slot="title">{{ item.title }}</span>
                            </template>
                        </el-submenu>
                    </template>
                </template>
            </el-menu>
        </div>
        <div class="sidebar width" v-if="subs">
            <el-menu class="sidebar-el-menu" :default-active="$router.path" text-color="#333" unique-opened @open="openSubs" @select="select">
                <el-submenu :index="item.index" :key="item.index" v-for="item in subs" v-if="item.is_show==2">
                    <template slot="title">
                        <span>{{ item.title }}</span>
                    </template>
                </el-submenu>
            </el-menu>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import bus from '../common/bus';
import { getUserInfo } from "../utils/user";
export default {
    data() {
        return {
            collapse: false,
            adminRouterList: [],   //管理员角色所赋予的路由
            isSuperAdmin: false,

            items: [],//可访问的路由表


            activeIndex: '',

            //  仅开放社区模块的名单列表
            commList: [
                '15026473653',
                '17302501365'
            ],
            subs: null,
        };
    },
    watch: {
        $router() {
            this.select(this.activeIndex)
        },
        adminRouterList() {
            this.adminRouterList = this.GET_ADMIN_ROUTER
        },

        siderRouter(val) {
            this.items = val;
        },
    },
    methods: {
        open(index) {
            this.setTopMenuTage(index)
            let subs = []
            this.items.map(item => {
                if (item.index === index) {
                    subs = item.subs
                }
                return subs
            })
            this.subs = subs
            if (this.subs) {
                this.$emit('width', 240)
            } else {
                this.$emit('width', 120)
                this.$router.push({ path: index })
            }
        },
        close() {
            this.$emit('width', 120)
        },
        openSubs(index) {
            this.$store.commit('initQueryParams', { page: 1, pagesize: 10 })
            this.$router.push({ path: index })
        },
        select(index) {
            this.activeIndex = index
        },
        setTopMenuTage(tag) {
            this.$store.dispatch("adminRouter/SET_TOP_MENU_TAG", tag).then((res) => {
            }).catch(() => {
                that.$message.error("设置左侧菜单标签错误");
            });
        }
    },
    computed: {
        ...mapState("adminRouter", ["adminRouter", "siderRouter"]),
        ...mapGetters("adminRouter", ["GET_ADMIN_ROUTER", "get_siderRouter"]),
        onRoutes() {
            return this.$route.path.replace('/', '');
        }
    },

    updated() {

    },

    mounted() {
        this.$nextTick(() => {
            this.items = this.$store.getters['adminRouter/get_siderRouter'];
        })
    },

    created() {

        let admin = getUserInfo()
        if (admin.is_super_admin) {
            this.isSuperAdmin = true
        }
        this.adminRouterList = this.GET_ADMIN_ROUTER





    },
};
</script>

<style scoped>
#sild {
    display: block;
    position: absolute;
    left: 0;
    top: 50px;
    bottom: 0;

    /* width: 240px; */
}
.sidebar >>> .el-menu {
    background: #031225;
}

.width >>> .el-submenu__title i {
    display: none;
}

#sild .sidebar {
    display: inline-block;
    height: 100%;
    overflow-y: scroll;
    width: 120px;
}

#sild .sidebar >>> .el-submenu__title {
    background: #031225;
}

#sild .sidebar >>> .is-opened .el-submenu__title {
    background: #409eff;
}

#sild .sidebar >>> .is-opened .el-submenu__title span {
    color: #fff;
}

#sild .sidebar >>> .is-opened .el-submenu__title i {
    color: #fff;
}

#sild .width >>> .el-submenu__title {
    text-align: center;
    background: #fff;
}

#sild .width >>> .is-opened .el-submenu__title {
    background-color: rgba(3, 18, 37, 0.1);
}

#sild .width >>> .is-opened .el-submenu__title span {
    color: #409eff;
}

.sidebar::-webkit-scrollbar {
    width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
    width: 120px;
}

.sidebar > ul {
    height: 100%;
}

#sild {
    display: block;
    position: absolute;
    left: 0;
    top: 50px;
    bottom: 0;
    /* width: 240px; */
}

.sidebar-el-menu {
    background: #041040;
}

.width .sidebar-el-menu {
    background: #ffffff;
}

.width >>> .el-submenu__title i {
    display: none;
}

.sidebar::-webkit-scrollbar {
    width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
    width: 120px;
}

.sidebar > ul {
    height: 100%;
}
</style>
